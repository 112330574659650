import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './css/ViewContact.css'; // Import your CSS file for styling
import HomeButton from './HomeButton';

const ViewContact = () => {
  const [contact, setContact] = useState(null);
  const { contactId } = useParams(); // Get contact ID from route parameters

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchContact = async () => {
      try {
        //LOCAL URL 
        //const response = await fetch(`http://192.168.1.86:3500/contacts/${contactId}`);

        //PRODUCTION URL 
        const response = await fetch(`https://pdocker2-ba81c9aadcfa.herokuapp.com/contacts/${contactId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setContact(data);
      } catch (error) {
        console.error('Error fetching contact:', error);
      }
    };

    if (contactId) {
      fetchContact();
    }
  }, [contactId]);

  if (!contact) {
    return <div>Loading...</div>;
  }



  return (
    <div className="view-contact">
           <Link to="/contacts" className="header-buttonview">Cancel</Link>
       
        <div className='view-contact-header'>
         
          <h2>Contact Details</h2>  
     
          </div>
          <div className="photo-placeholder">
        <i className="material-icons profileV">person</i>
      </div>
      <div className="contact-details">
        <h2>{contact.firstname} {contact.lastname}</h2>
        <p >Phone: {contact.phone}</p>
        {/* Display other contact details here */}
      </div>
      <div className='contactButtons'>
        <p className='msgContact'><i className="material-icons material-iconsContact" aria-hidden="true">message</i>message</p>
        <p className="callContact"> <i className="material-icons material-iconsContact" aria-hidden="true">phone</i>call</p>
      </div>
      <HomeButton />
    </div>
  );
};

export default ViewContact;
