// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Keypad.css */
.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px; /* Space between buttons */
    padding: 10px;
    background-color: #f0f0f0; /* iOS-like background color */
  }
  
  .keypad-save-button{
    background-color: blue;
    width: 100%;
    height: 3em;
    border: none;
    border-radius: .5em;
    color: white;

  }

  .keypad-button {
    font-size: 20px; /* Larger font size for keypad numbers */
    padding: 20px 0; /* Vertical padding */
    background-color: #ffffff; /* White button background */
    border: 1px solid #d0d0d0; /* Border color similar to iOS */
    border-radius: 50%; /* Circular buttons */
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  `, "",{"version":3,"sources":["webpack://./src/components/keypad.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,qCAAqC,EAAE,cAAc;IACrD,SAAS,EAAE,0BAA0B;IACrC,aAAa;IACb,yBAAyB,EAAE,8BAA8B;EAC3D;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;;EAEd;;EAEA;IACE,eAAe,EAAE,wCAAwC;IACzD,eAAe,EAAE,qBAAqB;IACtC,yBAAyB,EAAE,4BAA4B;IACvD,yBAAyB,EAAE,gCAAgC;IAC3D,kBAAkB,EAAE,qBAAqB;IACzC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;EAErB","sourcesContent":["/* Keypad.css */\n.keypad {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr); /* 3 columns */\n    gap: 10px; /* Space between buttons */\n    padding: 10px;\n    background-color: #f0f0f0; /* iOS-like background color */\n  }\n  \n  .keypad-save-button{\n    background-color: blue;\n    width: 100%;\n    height: 3em;\n    border: none;\n    border-radius: .5em;\n    color: white;\n\n  }\n\n  .keypad-button {\n    font-size: 20px; /* Larger font size for keypad numbers */\n    padding: 20px 0; /* Vertical padding */\n    background-color: #ffffff; /* White button background */\n    border: 1px solid #d0d0d0; /* Border color similar to iOS */\n    border-radius: 50%; /* Circular buttons */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
