// CalendarPage.js
import React, { useState, useEffect } from 'react';
import './css/Calander.css'; // Make sure to create the corresponding CSS file
import HomeButton from './HomeButton';
const Calendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  
    const calendarCells = [];
  
    // Fill in the blanks for the starting days of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarCells.push(<td key={`empty-${i}`} className="calendar-cell empty"></td>);
    }
  
    // Fill in the actual days
    for (let day = 1; day <= daysInMonth; day++) {
      calendarCells.push(
        <td key={day} className={`calendar-cell ${day === currentDate.getDate() ? 'current-day' : ''}`}>
          {day}
        </td>
      );
    }
  
    const handlePreviousMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
  
    const handleNextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    const renderCalendar = () => {
      let rows = [];
      let cells = [];
  
      calendarCells.forEach((cell, i) => {
        if ((i % 7) !== 0) {
          cells.push(cell);
        } else {
          let insertRow = cells.slice();
          rows.push(insertRow);
          cells = [];
          cells.push(cell);
        }
        if (i === calendarCells.length - 1) {
          let insertRow = cells.slice();
          rows.push(insertRow);
        }
      });
  
      return rows.map((d, i) => {
        return <tr key={`row-${i}`}>{d}</tr>;
      });
    };
  
    return (
      <div className="calendar-page">
        <header className="calendar-header">
          <button className="year-selector" onClick={handlePreviousMonth}>{'<'}</button>
          <div className="month-view">
            {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
          </div>
          <button className="year-selector" onClick={handleNextMonth}>{'>'}</button>
        </header>
        <table className="calendar-grid">
          <thead>
            <tr>
              {days.map(day => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderCalendar()}
          </tbody>
        </table>
        <footer className="calendar-footer">
          <button className="footer-button">Calendars</button>
          <button className="footer-button">Inbox</button>
        </footer>
        <HomeButton/>
      </div>
    );
  };
  
export default Calendar;


