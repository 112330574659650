// Chase.js
import React, { useState } from 'react';
import './css/Chase.css';
import HomeButton from './HomeButton';

const Chase = () => {
  const [showTransactions, setShowTransactions] = useState(false);

  const handleAccountClick = () => {
    setShowTransactions(true);
  };

  const transactions = [
    { id: 1, description: 'PYMT SENT APPLE CASH BALANCE ADD CUPERTINO CA', location: 'Pending', amount: -149.00 },
    { id: 2, description: 'POS DEBIT PUBLIX #1690 CUMMING GA', location: 'Pending', amount: -4.11 },
    { id: 3, description: 'POS DEBIT WM SUPERC WAL-MART SUP CUMMING GA', location: 'Pending', amount: -113.50 },
    { id: 4, description: 'ATM CASH WITHDRAWAL BANK OF AMERICA ATLANTA GA', location: 'Completed', amount: -100.00 },
    { id: 5, description: 'DIRECT DEPOSIT PAYROLL PAYMENTS', location: 'Completed', amount: 8300.33 },
    { id: 6, description: 'POS DEBIT TARGET #2334 ATLANTA GA', location: 'Pending', amount: -54.78 },
    { id: 7, description: 'TRANSFER TO SAVINGS ACCOUNT', location: 'Completed', amount: -300.00 },
    { id: 8, description: 'ZELLE PAYMENT TO JOHN DOE', location: 'Completed', amount: -75.00 },
    { id: 9, description: 'VENMO PAYMENT RECEIVED FROM ALICE', location: 'Completed', amount: 150.00 },
    { id: 10, description: 'POS DEBIT STARBUCKS #1034 ATLANTA GA', location: 'Pending', amount: -6.25 },
    { id: 11, description: 'ONLINE PURCHASE AMAZON.COM SEATTLE WA', location: 'Completed', amount: -89.99 },
    { id: 12, description: 'POS DEBIT CVS PHARMACY ATLANTA GA', location: 'Completed', amount: -18.23 },
    { id: 13, description: 'CHECK DEPOSIT BANK OF AMERICA', location: 'Completed', amount: 250.00 },
    { id: 14, description: 'MOBILE CHECK DEPOSIT', location: 'Completed', amount: 120.00 },
    { id: 15, description: 'PYMT SENT PAYPAL TRANSFER', location: 'Pending', amount: -60.00 },
    { id: 16, description: 'WIRE TRANSFER RECEIVED FROM JOHN SMITH', location: 'Completed', amount: 500.00 },
    { id: 17, description: 'POS DEBIT CHICK-FIL-A #3456 CUMMING GA', location: 'Pending', amount: -8.47 },
    { id: 18, description: 'TRANSFER TO CREDIT CARD PAYMENT', location: 'Completed', amount: -150.00 },
    { id: 19, description: 'GROCERY PURCHASE WHOLE FOODS ATLANTA GA', location: 'Completed', amount: -75.34 },
    { id: 20, description: 'POS DEBIT HOME DEPOT #1273 CUMMING GA', location: 'Pending', amount: -32.75 },
    { id: 21, description: 'ATM FEE REFUND', location: 'Completed', amount: 3.00 },
    { id: 22, description: 'RENT PAYMENT LANDLORD LLC', location: 'Completed', amount: -2200.00 },
    { id: 23, description: 'UTILITY BILL PAYMENT - GEORGIA POWER', location: 'Completed', amount: -100.00 },
    { id: 24, description: 'GYM MEMBERSHIP ONE LIFE', location: 'Pending', amount: -45.00 },
    { id: 25, description: 'POS DEBIT CHEVRON GAS STATION ATLANTA GA', location: 'Completed', amount: -40.15 }
  ];
  

  return (
    <div className="chase-container">
      {!showTransactions ? (
        <div className="account-screen">
          <div className="header">
            <button className="back-button">&#x2190;</button>
            <h2>Total Checking (...3653)</h2>
          </div>
          <div className="balance-info">
            <h1>$33,500.20</h1>
            <div className="balance-details">
              <div className="balance-row">
                <span className="balance-label">Available balance</span>
                <span className="balance-amount">$33,500.20</span>
              </div>
              <div className="balance-row">
                <span className="balance-label">Present balance</span>
                <span className="balance-amount">$33,500.20</span>
              </div>
            </div>
          </div>
          <center>
          <button onClick={handleAccountClick} className="show-details">Show details ▼</button>
          </center>
          <div className="account-actions">
            <button>Pay</button>
            <button>Transfer</button>
            <button>More</button>
          </div>
          <div className="manage-account">
            <h3>Manage account</h3>
            <p>Access tools & services for your account</p>
          </div>
        </div>
      ) : (
        <div className="transactions-screen">
          <button onClick={() => setShowTransactions(false)} className="back-button">&#x2190; Back</button>
          <h2>Transactions</h2>
          <ul className="transaction-list">
            {transactions.map((transaction) => (
              <li key={transaction.id} className="transaction-item">
                <div className="transaction-description">
                  <p>{transaction.description}</p>
                  <p className="transaction-location">{transaction.location}</p>
                </div>
                <div className="transaction-amount">${transaction.amount.toFixed(2)}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <HomeButton />
    </div>
  );
};

export default Chase;
