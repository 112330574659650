import React from 'react';
import './keypad.css'; 


const Keypad = ({ onKeyPress }) => {
    const keys = [
      { num: '1', letters: '' },
      { num: '2', letters: 'ABC' },
      { num: '3', letters: 'DEF' },
      { num: '4', letters: 'GHI' },
      { num: '5', letters: 'JKL' },
      { num: '6', letters: 'MNO' },
      { num: '7', letters: 'PQRS' },
      { num: '8', letters: 'TUV' },
      { num: '9', letters: 'WXYZ' },
      { num: '*', letters: '' },
      { num: '0', letters: '+' },
      { num: '#', letters: '' },
    ];
  

    <div className="keypad">
    {keys.map(key => (
      <button key={key} className="keypad-button" onClick={() => onKeyPress(key)}>
        {key}
        <div>{key.num}</div>
            <div className="letters">{key.letters}</div>
      </button>
    ))}
  </div>

    return (
      <div className="keypad">
        {keys.map((key, index) => (
          <div
            key={index}
            className="keypad-button"
            onClick={() => onKeyPress(key.num)}
          >
            <div>{key.num}</div>
            <div className="letters">{key.letters}</div>
          </div>
        ))}
      </div>
    );
  };
  
  

export default Keypad;