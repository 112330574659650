import React, { useState } from 'react';
import './css/Phone.css';
import HomeButton from './HomeButton';


const Phone = () => {
  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = (value) => {
    setInputValue(inputValue + value);
  };

  const handleDelete = () => {
    setInputValue(inputValue.slice(0, -1));
  };

  const handleCall = () => {
    // Implement call functionality here
    fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/makecall', {
   // fetch('http://192.168.1.86:3500/makecall', {
      method: 'POST'
    })

    alert(`Calling ${inputValue}...`);
  };

  const dialButtons = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'
  ];

  return (
    <div className="phone-container">
      <div className="display-section">
        <div className="number-display">{inputValue}
        <button className="delete-button" onClick={handleDelete}>
          <i className="material-icons" aria-hidden="true">backspace</i>
        </button></div>
       
      </div>
      <div className="dial-pad">
        {dialButtons.map((button, index) => (
          <button 
            key={index} 
            className="dial-button" 
            onClick={() => handleButtonClick(button)}
          >
            {button}
          </button>
        ))}
     
     
      </div>   <button className="call-button" onClick={handleCall}>
          <i className="material-icons" aria-hidden="true">phone</i>
        </button>
      <div className="phone-footer">
        {/* Placeholder for icons */}
        <i className="material-icons" aria-hidden="true">star</i>
        <i className="material-icons" aria-hidden="true">timer</i>
        <i className="material-icons" aria-hidden="true">person</i>
        <i className="material-icons active" aria-hidden="true">apps</i>
        <i className="material-icons" aria-hidden="true">voicemail</i>
      </div>
      <HomeButton />
    </div>
  );
};

export default Phone;
