import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Music.css';

const Music = () => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch songs from your database
    const fetchSongs = async () => {
      try {
        const response = await axios.get('https://pdocker2-ba81c9aadcfa.herokuapp.com/songs'); // Replace with your backend endpoint
        setSongs(response.data);
      } catch (error) {
        console.error('Error fetching songs:', error);
      }
    };

    fetchSongs();
  }, []);

  const playSong = (song) => {
    setCurrentSong(song);
    setIsPlaying(true);
  };

  return (
    <div className="music-container">
      <header>
        <h1>Library</h1>
        <button onClick={() => navigate('/home')}>Back</button>
      </header>
      <div className="songs-list">
        {songs.map((song) => (
          <div
            key={song.id}
            className="song-item"
            onClick={() => playSong(song)}
          >
            <div className="song-info">
              <h2>{song.title}</h2>
              <p>{song.artist}</p>
            </div>
          </div>
        ))}
      </div>
      {currentSong && (
        <div className="player">
          <audio
            src={currentSong.url}
            controls
            autoPlay={isPlaying}
            onPause={() => setIsPlaying(false)}
            onPlay={() => setIsPlaying(true)}
          />
          <p>Now Playing: {currentSong.title}</p>
        </div>
      )}
    </div>
  );
};

export default Music;
