import React, { useState } from 'react';
import './css/Notes.css';
// import HomeButton from './HomeButton';
import Homebtn from './HomeButton';

const Notes = () => {
  const [notes, setNotes] = useState([
    { id: 1, content: 'First Note' },
    // ... more notes
  ]);

  // Function to add a new note
  const addNewNote = () => {
    const newNote = { id: Date.now(), content: 'New Note' };
    setNotes([...notes, newNote]);
  };

  return (
    <div className="notes-app">
      <h1 className='headingnotes'>Notes</h1>
      <div className="notes-list">
        {notes.map(note => (
          <div key={note.id} className="note-item">
            <span>{note.content}</span>
            <div className='note-buttons'>
            <button className="edit-button">
              <i className="material-icons material-iconsNotes">edit</i>
            </button>
            <button className="delete-notes">
              <i className="material-icons material-iconsNotes">delete</i>
            </button>
            </div>
          </div>
        ))}
      </div>
      <button className="add-note-button" onClick={addNewNote}>Add Note</button>
      <Homebtn />
    </div>
  );
};

export default Notes;
