import React, { useState } from 'react';
import './css/Calculator.css';
import HomeButton from './HomeButton';

const Calculator = () => {
  const [display, setDisplay] = useState('');
  
  const handleClick = (value) => {
    if (value === 'C') {
      setDisplay('');
    } else if (value === '=') {
      try {
        setDisplay(eval(display).toString());
      } catch {
        setDisplay('Error');
      }
    } else {
      setDisplay(display + value);
    }
  };

  return (
    <div className="calculator">
      <div className="calculator-display">{display}</div>
      <div className="calculator-buttons">
        {['C', '/', '*', '-', 7, 8, 9, '+', 4, 5, 6, '=', 1, 2, 3, 0, '.'].map((btn, i) => (
          <button key={i} onClick={() => handleClick(btn.toString())}>
            {btn}
          </button>
        ))}
      </div>
      <HomeButton />
    </div>
  );
};

export default Calculator;
