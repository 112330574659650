// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ComposeMessage.css */

.compose-container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.compose-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f8f7;
  border-bottom: 1px solid #d1d1d1;
}

.cancel-button, .send-button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.new-message-title {
  font-weight: bold;
  font-size: 18px;
}

.compose-to {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #d1d1d1;
}

.to-input {
  width: 100%;
  border: none;
  font-size: 16px;
}

.compose-message {
  padding: 10px;
  background-color: #fff;
  height: 90vh;
}

.message-input {
  width: 100%;
  height: 200px;
  border: none;
  font-size: 16px;
  resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/ComposeMessage.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,2DAA2D;AAC7D;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,eAAe;EACf,YAAY;AACd","sourcesContent":["/* ComposeMessage.css */\n\n.compose-container {\n  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;\n}\n\n.compose-header {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  background-color: #f8f8f7;\n  border-bottom: 1px solid #d1d1d1;\n}\n\n.cancel-button, .send-button {\n  background: none;\n  border: none;\n  font-weight: bold;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.new-message-title {\n  font-weight: bold;\n  font-size: 18px;\n}\n\n.compose-to {\n  padding: 10px;\n  background-color: #fff;\n  border-bottom: 1px solid #d1d1d1;\n}\n\n.to-input {\n  width: 100%;\n  border: none;\n  font-size: 16px;\n}\n\n.compose-message {\n  padding: 10px;\n  background-color: #fff;\n  height: 90vh;\n}\n\n.message-input {\n  width: 100%;\n  height: 200px;\n  border: none;\n  font-size: 16px;\n  resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
