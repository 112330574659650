// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photos-container {
    padding: 20px;
    background-color: #f0f0f5;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  h1 {
    text-align: center;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  .photos-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .photo-item {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .photo-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .photo-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    cursor: pointer;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Photos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,8JAA8J;EAChK;;EAEA;IACE,aAAa;IACb,oDAAoD;IACpD,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,wCAAwC;IACxC,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,aAAa;EACf","sourcesContent":[".photos-container {\n    padding: 20px;\n    background-color: #f0f0f5;\n    height: 100vh;\n    overflow-y: scroll;\n    overflow-x: hidden;\n  }\n  \n  h1 {\n    text-align: center;\n    color: #333;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  }\n  \n  .photos-grid {\n    display: grid;\n    grid-template-columns: repeat(2, minmax(100px, 1fr));\n    gap: 10px;\n  }\n  \n  .photo-item {\n    border-radius: 8px;\n    overflow: hidden;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n  }\n  \n  .photo-item img {\n    width: 100%;\n    height: auto;\n    display: block;\n  }\n  \n  .photo-modal {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.8);\n    z-index: 1000;\n    cursor: pointer;\n  }\n  \n  .modal-content {\n    max-width: 90%;\n    max-height: 90%;\n    border-radius: 8px;\n  }\n  \n  .close-button {\n    position: absolute;\n    top: 20px;\n    right: 30px;\n    color: white;\n    font-size: 40px;\n    font-weight: bold;\n    cursor: pointer;\n    z-index: 1001;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
