// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-clock_face {
    position: relative;
}


.clock-container {
    padding: 20px;
    text-align: center;
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    height: 100vh !important;
  }
  
  .react-clock {
    margin-top: 10px;
    border: 4px solid #009688;
    border-radius: 50%;
    width: 100% !important;
    display: block !important;
    max-width: 328px;
    max-height: 328px;
    height: 100vh !important;
    
  }
  
  
  .time-label {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .react-clock .react-clock_hand--hour,
  .react-clock .react-clock_hand--minute,
  .react-clock .react-clock_hand--second {
    background-color: #009688; /* This will color the hands to make them visible */
    width: 3px; /* Adjust width as needed */
    height: 5px;
    color: black;
  }
  
  /* Center the hands on the clock face */
  .react-clock_hand--hour__body,
  .react-clock_hand--minute__body,
  .react-clock_hand--second__body {
    top: 50%; /* Center vertically */
    transform-origin: center bottom; /* Ensure they rotate around the bottom center */
  }`, "",{"version":3,"sources":["webpack://./src/components/clock.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;IACxC,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;;EAE1B;;;EAGA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;;;IAGE,yBAAyB,EAAE,mDAAmD;IAC9E,UAAU,EAAE,2BAA2B;IACvC,WAAW;IACX,YAAY;EACd;;EAEA,uCAAuC;EACvC;;;IAGE,QAAQ,EAAE,sBAAsB;IAChC,+BAA+B,EAAE,gDAAgD;EACnF","sourcesContent":[".react-clock_face {\n    position: relative;\n}\n\n\n.clock-container {\n    padding: 20px;\n    text-align: center;\n    background-color: #f3f3f3;\n    border-radius: 10px;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\n    height: 100vh !important;\n  }\n  \n  .react-clock {\n    margin-top: 10px;\n    border: 4px solid #009688;\n    border-radius: 50%;\n    width: 100% !important;\n    display: block !important;\n    max-width: 328px;\n    max-height: 328px;\n    height: 100vh !important;\n    \n  }\n  \n  \n  .time-label {\n    font-size: 16px;\n    color: #333;\n    margin-bottom: 10px;\n  }\n  \n  .react-clock .react-clock_hand--hour,\n  .react-clock .react-clock_hand--minute,\n  .react-clock .react-clock_hand--second {\n    background-color: #009688; /* This will color the hands to make them visible */\n    width: 3px; /* Adjust width as needed */\n    height: 5px;\n    color: black;\n  }\n  \n  /* Center the hands on the clock face */\n  .react-clock_hand--hour__body,\n  .react-clock_hand--minute__body,\n  .react-clock_hand--second__body {\n    top: 50%; /* Center vertically */\n    transform-origin: center bottom; /* Ensure they rotate around the bottom center */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
