import React, { useState } from 'react';
import './css/AddContact.css'; // Create and import your CSS file for styling
import HomeButton from './HomeButton';
import { Link } from 'react-router-dom'; // Import Link
import Keypad from './keypad';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const AddContact = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showKeypad, setShowKeypad] = useState(false);
  const [showAddPhoneLabel, setShowAddPhoneLabel] = useState(true);
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    photo: '',
    email: ''
  });

  const handleKeypadPress = (key) => {
    let newPhone = phoneNumber + key;
    if (newPhone.length <= 10) {
      setPhoneNumber(newPhone);
    }
  };
  

  const toggleKeypad = () => {
    setShowKeypad(!showKeypad);
    setShowAddPhoneLabel(!showAddPhoneLabel);
  };

  const handleSavePhone = () => {
    if (phoneNumber.length === 7 || phoneNumber.length === 10) {
      setContact({ ...contact, phone: phoneNumber });
      setShowKeypad(false);
      toastr.success('Number Added for Contact.');
      
      // Do not clear phoneNumber here if you want it to be visible after saving
    } else {
      toastr.warning('Please enter a valid phone number (7 or 10 digits).');
    }
  };
  

  function returntocontacts(){ 
    window.location.assign('/contacts'); 
  }


  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleSavePhone();
    
    const token = sessionStorage.getItem('token'); // Retrieve the token from session storage
    if (!token) {
      toastr.error('You are not logged in.');
      return;
    }
  
    try {

      //production URL 
      // new heroku https://pdocker2-ba81c9aadcfa.herokuapp.com
      //const response = await fetch('https://whispering-basin-90626-d9b6eeca5ce9.herokuapp.com/http://44.210.187.185:3500/contacts/add', {
      const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/contacts/add', {
      //LocalURL
      //const response = await fetch('http://192.168.1.86:3500/contacts/add', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          firstname: contact.firstName,
          lastname: contact.lastName,
          phone: contact.phone,
          // Include other contact details as necessary
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toastr.success('Contact added successfully');
        returntocontacts(); // Navigate back to the contacts list
      } else {
        throw new Error(data.error || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error submitting contact:', error);
      toastr.error(`Error submitting contact: ${error.message}`);
    }
  };
  

  return (
    <div className="add-contact">
    <div className="add-contact-header">
    <Link to="/contacts" className="header-button">Cancel</Link>
      <h2>New Contact</h2>
      <button className="header-button" onClick={handleSubmit}>Done</button>
    </div>
    <div className="add-photo-section">
      <div className="photo-placeholder">
        <i className="material-icons profile">person</i>
      </div>
      <button className="add-photo-button">Add Photo</button>
    </div>
    <form className="add-contact-form" onSubmit={handleSubmit}>
      <input type="text" id="firstName" name="firstName" placeholder="First name" onChange={handleChange} />
      <input type="text" id="lastName" name="lastName" placeholder="Last name" onChange={handleChange} />
      {/* Add Company Input if needed */}
      {showAddPhoneLabel && (
      <button type="button" className="add-field-button" id="addphonelabel" onClick={toggleKeypad}>
        <p className="button-addphone">+</p> add phone
      </button>
          )}
{contact.phone && (
  <div className="saved-phone-number">
    <input
      type="tel"
      id="savedPhone"
      value={contact.phone}
      readOnly
    />
  </div>
)}

      {showKeypad && (
        <>
             <input
              type="tel"
              className="phone-input"
              value={phoneNumber}
              placeholder="Enter phone number"
              readOnly
            />
          <Keypad onKeyPress={handleKeypadPress} />
          <button className="keypad-save-button" onClick={handleSavePhone}>Save</button>
        </>
      )}
      <button type="button" className="add-field-button"><p className="button-addphone">+</p> add email</button>
      {/* Add additional fields as needed */}
      {/* Remove the Photo URL field if you are implementing the add photo functionality */}
     

    </form>
    <HomeButton />
  </div>
  

  );
};

export default AddContact;
