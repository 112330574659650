// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-app {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #fff;
  color: #333;
  margin: 0 auto; 
  width: 100vw;
  height: 100vh;
  max-width: 490px;
  max-width: 430px;
  overflow-y: auto; /* Enable scrolling if content is longer than the screen */
  overflow-x: hidden; 
}

.notes-list {
  margin-top: 20px;
  width: 95vw;
  max-width: 100%;
}

.headingnotes {
  padding-right: 95vw;

  padding-left: 9px !important;
}



.note-item {
  padding:  .75em;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button, .delete-notes {
  margin-left: 1em;
  /* padding: .35em .75em; */
  background-color: #ffffff00;
  color: #007bff;;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-note-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/Notes.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB,EAAE,0DAA0D;EAC5E,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mBAAmB;;EAEnB,4BAA4B;AAC9B;;;;AAIA;EACE,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,2BAA2B;EAC3B,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".notes-app {\n  font-family: Arial, sans-serif;\n  padding: 20px;\n  background-color: #fff;\n  color: #333;\n  margin: 0 auto; \n  width: 100vw;\n  height: 100vh;\n  max-width: 490px;\n  max-width: 430px;\n  overflow-y: auto; /* Enable scrolling if content is longer than the screen */\n  overflow-x: hidden; \n}\n\n.notes-list {\n  margin-top: 20px;\n  width: 95vw;\n  max-width: 100%;\n}\n\n.headingnotes {\n  padding-right: 95vw;\n\n  padding-left: 9px !important;\n}\n\n\n\n.note-item {\n  padding:  .75em;\n  border-bottom: 1px solid #ccc;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.edit-button, .delete-notes {\n  margin-left: 1em;\n  /* padding: .35em .75em; */\n  background-color: #ffffff00;\n  color: #007bff;;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.add-note-button {\n  margin-top: 20px;\n  padding: 10px 20px;\n  background-color: #28a745;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
