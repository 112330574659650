// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoadingBanner.css */

.loading-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* or absolute */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00081a; /* White background with a slight transparency */
    z-index: 1000; /* Ensure it sits above other content */
  }

  .loadingimage {
        max-width: 330px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Loadingbanner.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe,EAAE,gBAAgB;IACjC,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,yBAAyB,EAAE,gDAAgD;IAC3E,aAAa,EAAE,uCAAuC;EACxD;;EAEA;QACM,gBAAgB;EACtB","sourcesContent":["/* LoadingBanner.css */\n\n.loading-banner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed; /* or absolute */\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: #00081a; /* White background with a slight transparency */\n    z-index: 1000; /* Ensure it sits above other content */\n  }\n\n  .loadingimage {\n        max-width: 330px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
