import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as faceapi from 'face-api.js';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import logo from './img/ccOSlogo.png';
import './css/Login.css';
import Loadingbanner from './Loadingbanner';

const Login = () => {
  const [showLoadingBanner, setShowLoadingBanner] = useState(true);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [showFacialRecognition, setShowFacialRecognition] = useState(false); // Track when to show facial recognition
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFacialLogin = async (username) => {
    if (!modelsLoaded) {
      toastr.error('Face recognition models are still loading. Please try again.');
      return;
    }

    try {
      const videoElement = document.getElementById('videoElement');

      // Capture face descriptor from webcam
      const detection = await faceapi.detectSingleFace(videoElement).withFaceLandmarks().withFaceDescriptor();
      if (!detection) {
        toastr.error('No face detected. Please try again.');
        return;
      }

      const descriptor = Array.from(detection.descriptor);

      // Send face descriptor to the backend for verification
      //const response = await fetch('http://localhost:3500/users/facial-login', {
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/users/facial-login', {
       
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, descriptor }),
      });

      const data = await response.json();
      if (response.ok) {
        const token = sessionStorage.getItem('token'); // Retrieve token from session storage
        if (token) {
          toastr.success(`Facial login successful! Welcome, ${username}`);
          navigate('/home');
        } else {
          toastr.error('Facial login successful, but no token found in session storage.');
        }
      } else {
        toastr.error(data.error || 'Facial login failed.');
      }
    } catch (error) {
      toastr.error(`Facial login error: ${error.message}`);
      console.error('Facial login error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(' https://pdocker2-ba81c9aadcfa.herokuapp.com/users/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (response.ok) {
        toastr.success('Password login successful!');
        sessionStorage.setItem('token', data.token);

     
        if (credentials.username.toLowerCase() === 'ccasey') {
          // Trigger facial recognition only for 'ccasey'
          if (data.hasFaceDescriptor) {
            toastr.info('Complete login using facial recognition.');
            setShowFacialRecognition(true); // Show facial recognition box
          } else {
            toastr.info('No facial data found. Let’s set it up now.');
            setShowFacialRecognition(true); // Show facial recognition box
            await setupFacialRecognition(credentials.username);
          }
        } else {
          // Directly navigate to the home page for other users
          navigate('/home');
        }
      } else {
        throw new Error(data.error || 'Password login failed');
      }
    } catch (error) {
      toastr.error(`Login error: ${error.message}`);
      console.error('Login error:', error);
    }
  };

  const setupFacialRecognition = async (username) => {
    if (!modelsLoaded) {
      toastr.error('Face recognition models are still loading. Please try again.');
      return;
    }

    try {
      const videoElement = document.getElementById('videoElement');
      if (!videoElement) {
        toastr.error('Video element not found in the DOM.');
        return;
      }
      

      // Capture face descriptor from webcam
      const detection = await faceapi.detectSingleFace(videoElement).withFaceLandmarks().withFaceDescriptor();
      if (!detection) {
        toastr.error('No face detected. Please try again.');
        return;
      }

      const descriptor = Array.from(detection.descriptor);

      // Send face descriptor to the backend for saving
      const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/users/save-face', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, descriptor }),
      });

      if (response.ok) {
        toastr.success('Facial recognition setup successful!');
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to set up facial recognition');
      }
    } catch (error) {
      toastr.error(`Facial setup error: ${error.message}`);
      console.error('Facial setup error:', error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingBanner(false); // Hide the loading banner after 1.5 seconds
    }, 1500);
    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    if (showFacialRecognition) {
      const video = document.getElementById('videoElement');
      let stream;

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          video.srcObject = mediaStream;
          stream = mediaStream;
        })
        .catch((err) => {
          console.error('Error accessing webcam:', err);
          toastr.error('Unable to access webcam. Please check your browser settings.');
        });

      return () => {
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        if (video) {
          video.srcObject = null;
        }
      };
    }
  }, [showFacialRecognition]);

  useEffect(() => {
    const loadModels = async () => {
      const modelUri = '/models/weights';

      try {
        await Promise.all([
          faceapi.nets.ssdMobilenetv1.loadFromUri(modelUri),
          faceapi.nets.faceRecognitionNet.loadFromUri(modelUri),
          faceapi.nets.faceLandmark68Net.loadFromUri(modelUri),
        ]);
        setModelsLoaded(true); // Set state to indicate models are loaded
        console.log('Models loaded');
      } catch (error) {
        console.error('Error loading face-api.js models:', error);
        toastr.error('Failed to load face recognition models.');
      }
    };
    loadModels();
  }, []);

  return (
    <div className="login-container">
      {showLoadingBanner && <Loadingbanner />}
      <h2>Login</h2>
      <div className="loginlogo-container">
        <img src={logo} className="loginimage" alt="Colin Casey OS Logo" />
      </div>
      {/* Show the login form */}
      {!showFacialRecognition && (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
      )}
      {/* Show the facial recognition box only after successful login */}
      {showFacialRecognition && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <video
            id="videoElement"
            autoPlay
            muted
            style={{
              width: '320px',
              height: '240px',
              border: '2px solid #ccc',
              borderRadius: '10px',
              backgroundColor: '#000',
            }}
          ></video>    <br></br>
          <br></br>
          <button className='facialbutton' onClick={() => handleFacialLogin(credentials.username)}>
            Complete Facial Login
          </button>
        </div>
      )}
      <p className="noaccounttext">
        Don't have an account? <Link to="/register">Register here</Link>
      </p>
    </div>
  );
};

export default Login;
