// import React, { useEffect, useState } from 'react';
// import './css/Photos.css'; // Make sure to create and import a CSS file for styling
// import HomeButton from './HomeButton';

// const Photos = ({ userId }) => {
//   const [photos, setPhotos] = useState([]);

//   useEffect(() => {
//     fetchPhotos();
//   }, []);

//   const fetchPhotos = () => {
//     const token = sessionStorage.getItem('authToken'); // Adjust if using a different storage method

//     fetch(`http://192.168.1.86:3500/photos/${userId}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => setPhotos(data))
//       .catch(error => console.error('Error fetching photos:', error));
//   };

//   return (
//     <div className="photos-container">
//       <h1>Photos</h1>
//       <div className="photos-grid">
//         {photos.map(photo => (
//           <div key={photo.path} className="photo-item">
//             <img src={`http://192.168.1.86:3500/photos/${photo.path}`} alt="User Photo" />
//           </div>
//         ))}
//       </div>
//       <HomeButton/>
//     </div>
//   );
// };

// export default Photos;



import React, { useEffect, useState } from 'react';
import './css/Photos.css'; // Make sure to create and import a CSS file for styling
import HomeButton from './HomeButton';

const Photos = ({ userId }) => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const token = sessionStorage.getItem('token'); // Ensure token is correctly retrieved

  useEffect(() => {
    fetchPhotos();
  }, []);

  const fetchPhotos = () => {
    fetch(`https://pdocker2-ba81c9aadcfa.herokuapp.com/displayphotos/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setPhotos(data))
      .catch(error => console.error('Error fetching photos:', error));
  };

  const openPhoto = (photo) => {
    setSelectedPhoto(photo);
  };

  const closePhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="photos-container">
      <h1>Photos</h1>
      <div className="photos-grid">
        {photos.map(photo => (
          <div key={photo.id} className="photo-item" onClick={() => openPhoto(photo)}>
            <img src={`https://pdocker2-ba81c9aadcfa.herokuapp.com/photos/image/${photo.id}`} alt="User Photo" />
          </div>
        ))}
      </div>
      {selectedPhoto && (
        <div className="photo-modal" onClick={closePhoto}>
          <span className="close-button">&times;</span>
          <img src={`https://pdocker2-ba81c9aadcfa.herokuapp.com/photos/image/${selectedPhoto.id}`} alt="User Photo" className="modal-content" />
        </div>
      )}
      <HomeButton />
    </div>
  );
};

export default Photos;
