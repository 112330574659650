import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/Email.css';
import HomeButton from './HomeButton';

const Email = () => {
  const { emailId } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchEmail = async () => {
      try {
        //const response = await fetch(`https://privatedocker-0e11c3e5520d.herokuapp.com:3500/emails/${emailId}`, {
        const response = await fetch(`https://pdocker2-ba81c9aadcfa.herokuapp.com/emails/${emailId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEmail(data);
      } catch (error) {
        console.error('Error fetching email:', error);
      }
    };

    fetchEmail();
  }, [emailId]);

  if (!email) {
    return <div>Loading...</div>;
  }

  return (
    <div className="email">
       <button className="back-button" onClick={() => navigate(-1)}>
          ←
        </button>
      <div className="email-header">
       
        <h1>{email.subject}</h1>
        <div className="email-details">
          <div>From: <b>{email.sender}</b></div>
          <div>Date: {new Date(email.date).toLocaleString()}</div>
        </div>
      </div>
      <div className="email-body">
        {email.body}
      </div>
      {email.attachments && email.attachments.length > 0 && (
        <div className="attachment">
          {email.attachments.map((attachment, index) => (
            <a key={index} href={attachment.url} target="_blank" rel="noopener noreferrer">
              <img src="/path/to/icon.png" alt="Attachment Icon" />
              {attachment.name}
            </a>
          ))}
        </div>
      )}
      <HomeButton className="home-button" />
    </div>
  );
};

export default Email;
