import { useEffect, useState } from 'react';
import Clock from 'react-clock';
import HomeButton from './HomeButton';
import './clock.css';  // Ensure your CSS file includes your desired styles
import 'react-clock/dist/Clock.css';


function Cclock() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <div className="clock-container">
     <center>
        <br></br>
        <br></br>
        <br></br>
        <Clock value={currentTime} className="react-clock" />
        </center>
      <HomeButton/>
    </div>
  );
}

export default Cclock;
