// HomeButton.js
import React from 'react';
import './YourButtonStyles.css';
import { useNavigate } from 'react-router-dom';

const HomeButton = () => {
  const navigate = useNavigate();
  function refreshPage(){ 
    // Request full screen
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { // Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
      document.documentElement.msRequestFullscreen();
    }
    
   // window.location.assign('/home'); 
    navigate('/home'); // Adjust as necessary
  }

  return (
    <div className="homebuttonrow">
      <button onClick={ refreshPage } variant="secondary" size="sm" className="home-button">Home</button>
    </div>
  );
}

export default HomeButton;
