import React, { useEffect, useState } from 'react';
import './css/Contacts.css';
import HomeButton from './HomeButton';
import { Link, useNavigate } from 'react-router-dom';



// const contactData = [
//   { name: 'Apple Inc.', initials: 'A' },
//   { name: 'Bryan Hinkle', initials: 'BH' },
//   // ... more contacts
// ];

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const handleContactClick = (contactId) => {
    navigate(`/contact/${contactId}`);
  };

  useEffect(() => {
    const fetchContacts = async () => {
     const token = sessionStorage.getItem('token'); 
      try {

      
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/contacts/list', {
          //const response = await fetch('http://192.168.1.86:3500/contacts/list', {
            //if this is prod, and u want it connected to db 
            //use ec2 ip of whispering basin 44.210.187.185:3500/contacts/list 
          //const response = await fetch('http://localhost:3500/contacts/list', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
          });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);



  return (
    <div className="contacts">
      <div className="contacts-header">
        <h1>Contacts 
        </h1><Link to="/add" className="button-add">+</Link>
      </div>
     
      <div className="search-bar">
        <input type="text" className="search-input" placeholder="Search" />
      
      </div>
      {/* <div className="contact-list">
        {contactData.map((contact, index) => (
          <div key={index} className="contact-item">
            <div className="contact-initials">{contact.initials}</div>
            <div className="contact-name">{contact.name}</div>
          </div>
        ))}
      </div> */}
      <div className="contact-list">
        {contacts.map((contact, index) => (
          <div key={index} className="contact-item" onClick={() => handleContactClick(contact.id)} >
            <div className="contact-initials">
              {contact.firstname[0]}{contact.lastname[0]}
            </div>
            <div className="contact-name">{contact.firstname} {contact.lastname}</div>
          </div>
        ))}
      </div>



      <HomeButton />
    </div>
  );
};

export default Contacts;
