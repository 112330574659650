// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .mail-header {
    background-color: #f8f8f8;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .mail-header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  .email-list {
    flex: 1 1;
    overflow-y: auto;
    background-color: white;
  }
  
  .email-item {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .email-item:hover {
    background-color: #f0f0f0;
  }
  
  .email-sender {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .email-subject {
    margin-bottom: 5px;
  }
  
  .email-date {
    color: #888;
    font-size: 0.9em;
  }
  
  .home-button {
    margin: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Mail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,gCAAgC;IAChC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,gCAAgC;IAChC,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".mail {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n  }\n  \n  .mail-header {\n    background-color: #f8f8f8;\n    padding: 10px;\n    border-bottom: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .mail-header h1 {\n    margin: 0;\n    font-size: 2.5em;\n  }\n  \n  .email-list {\n    flex: 1;\n    overflow-y: auto;\n    background-color: white;\n  }\n  \n  .email-item {\n    padding: 15px;\n    border-bottom: 1px solid #e0e0e0;\n    cursor: pointer;\n  }\n  \n  .email-item:hover {\n    background-color: #f0f0f0;\n  }\n  \n  .email-sender {\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .email-subject {\n    margin-bottom: 5px;\n  }\n  \n  .email-date {\n    color: #888;\n    font-size: 0.9em;\n  }\n  \n  .home-button {\n    margin: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
