// import React, { useRef, useEffect, useState } from 'react';
// import HomeButton from './HomeButton';

// const Camera = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [photo, setPhoto] = useState(null);

//   useEffect(() => {
//     const getCameraStream = async () => {
//       try {
//         await new Promise(resolve => setTimeout(resolve, 1000)); // Adding delay for Safari
//         const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream;
//         }
//       } catch (error) {
//         console.error('Error accessing the camera: ', error);
//         alert('Error accessing the camera. Please ensure you have granted permissions.');
//       }
//     };

//     getCameraStream();

//     return () => {
//       if (videoRef.current && videoRef.current.srcObject) {
//         videoRef.current.srcObject.getTracks().forEach(track => track.stop());
//       }
//     };
//   }, []);

//   const takePhoto = () => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//     context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
//     const dataUrl = canvas.toDataURL('image/png');
//     setPhoto(dataUrl);

//     // Save the photo to the database
//     //fetch('http://localhost:3500/save-photo', {
//       fetch('https://whispering-basin-90626-d9b6eeca5ce9.herokuapp.com/http://44.210.187.185:3500/save-photo', {
     
//      // fetch('https://f3b7-2600-1700-5c51-330-ad52-416a-690b-d816.ngrok-free.app:3500/save-photo', {
//       //add these functions to ur node in aws ec2 

//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ photo: dataUrl })
//     })
//       .then(response => response.text())
//       .then(data => console.log(data))
//       .catch(error => console.error('Error saving photo:', error));
//   };

//   return (
//     <div>
//       <h1>Camera</h1>
//       <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto' }} />
//       <button onClick={takePhoto}>Take Photo</button>
//       <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
//       {photo && (
//         <div>
//           <h2>Captured Photo</h2>
//           <img src={photo} alt="Captured" />
//         </div>
//       )}
//       <HomeButton />
//     </div>
//   );
// };

// export default Camera;




// import React, { useRef, useEffect, useState } from 'react';
// import HomeButton from './HomeButton';

// const Camera = ({ id }) => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [photo, setPhoto] = useState(null);
//   const [userPhotos, setUserPhotos] = useState([]);

//   useEffect(() => {
//     const getCameraStream = async () => {
//       try {
//         await new Promise(resolve => setTimeout(resolve, 1000)); // Adding delay for Safari
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream;
//         }
//       } catch (error) {
//         console.error('Error accessing the camera: ', error);
//         alert('Error accessing the camera. Please ensure you have granted permissions.');
//       }
//     };

//     getCameraStream();

//     return () => {
//       if (videoRef.current && videoRef.current.srcObject) {
//         videoRef.current.srcObject.getTracks().forEach(track => track.stop());
//       }
//     };
//   }, []);

//   const takePhoto = () => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//     context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
//     const dataUrl = canvas.toDataURL('image/png');
//     setPhoto(dataUrl);

//     // Save the photo to the server
//     fetch('https://whispering-basin-90626-d9b6eeca5ce9.herokuapp.com/http://44.210.187.185:3500/save-photo', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ photo: dataUrl, id })
//     })
//       .then(response => response.json())
//       .then(data => {
//         console.log(data);
//         // Fetch the updated list of user photos
//         fetchUserPhotos();
//       })
//       .catch(error => console.error('Error saving photo:', error));
//   };

//   const fetchUserPhotos = () => {
//     fetch(`http://192.168.1.86:3500/user-photos/${id}`)
//       .then(response => response.json())
//       .then(data => setUserPhotos(data))
//       .catch(error => console.error('Error fetching user photos:', error));
//   };

//   useEffect(() => {
//     fetchUserPhotos();
//   }, []);

//   return (
//     <div>
//       <h1>Camera</h1>
//       <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto' }} />
//       <button onClick={takePhoto}>Take Photo</button>
//       <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
//       {photo && (
//         <div>
//           <h2>Captured Photo</h2>
//           <img src={photo} alt="Captured" />
//         </div>
//       )}
//       <h2>User Photos</h2>
//       <div>
//         {userPhotos.map(photo => (
//           <img key={photo.path} src={`http://192.168.1.86:3500/photos/${photo.path}`} alt="User Photo" />
          
//         ))}
//       </div>
//       <HomeButton />
//     </div>
//   );
// };

// export default Camera;



import React, { useRef, useEffect, useState } from 'react';

const Camera = ({ userId }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [userPhotos, setUserPhotos] = useState([]);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adding delay for Safari
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing the camera: ', error);
        alert('Error accessing the camera. Please ensure you have granted permissions.');
      }
    };

    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => {
          console.log('Stopping track:', track);
          track.stop();
        });
        console.log('Camera stream stopped');
      }
    };
  }, []);

  const takePhoto = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/png');
    setPhoto(dataUrl);
    const token = sessionStorage.getItem('authToken'); // Ensure token is correctly retrieved

    fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/save-photo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ photo: dataUrl, userId })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        // Fetch the updated list of user photos
        fetchUserPhotos();
      })
      .catch(error => console.error('Error saving photo:', error));
  };

  const fetchUserPhotos = () => {
    const token = sessionStorage.getItem('token'); // Ensure token is correctly retrieved
    fetch(`https://pdocker2-ba81c9aadcfa.herokuapp.com/user-photos/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setUserPhotos(data))
      .catch(error => console.error('Error fetching user photos:', error));
  };

  useEffect(() => {
    fetchUserPhotos();
  }, []);

  const handleHomeClick = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => {
        console.log('Stopping track:', track);
        track.stop();
      });
      console.log('Camera stream stopped');
    }
    window.location.assign('/home'); 
  };

  return (
    <div>
      <h1>Camera</h1>
      <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto' }} />
      <center><button onClick={takePhoto}>Take Photo</button></center>
      <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
      <div className="homebuttonrow">
        <button onClick={handleHomeClick} variant="secondary" size="sm" className="home-button">Home</button>
      </div>
    </div>
  );
};

export default Camera;

