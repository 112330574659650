import React, { useEffect, useState } from 'react';
import HomeButton from './HomeButton';

const GMapsIframe = () => {
  const [locationSrc, setLocationSrc] = useState("https://maps.google.com?output=embed");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocationSrc(`https://maps.google.com/?q=${latitude},${longitude}&output=embed`);
      }, () => {
        console.error("Geolocation is supported, but it failed");
      });
    }
  }, []);

  return (
    <div style={{ height: '100vh', backgroundColor: '#8AB4F8'}}>
      <iframe
        src={locationSrc}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0, height: '80vh', borderRadius: '40px' }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
      <HomeButton />
    </div>
  );
};

export default GMapsIframe;
