// Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import logo from './img/ccOSlogo.png'; 
import './css/Login.css';
import Loadingbanner from './Loadingbanner';

const Login = () => {
  const [showLoadingBanner, setShowLoadingBanner] = useState(true);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming you have an endpoint to authenticate a user at /users/login
      
      //this is for ur iphone to connect to production 
      const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/users/login', {
      //const response = await fetch('https://whispering-basin-90626-d9b6eeca5ce9.herokuapp.com/http://44.210.187.185:3500/users/login', {
      //this is for local 
   //  const response = await fetch('http://localhost:3500/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (response.ok) {
        toastr.success('Login successful!');
        sessionStorage.setItem('token', data.token);

        //request full screen access
        
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { // Firefox
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
          document.documentElement.msRequestFullscreen();
        }
        // Store the token and navigate to the home screen or wherever appropriate
        // localStorage.setItem('token', data.token);
        navigate('/home'); // Adjust as necessary
      } else {
        throw new Error(data.error || 'Login failed');
      }
    } catch (error) {
      toastr.error(`Login error: ${error.message}`);
      console.error('Login error:', error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingBanner(false); // Hide the loading banner after 1.5 seconds
    }, 1500);
    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div className="login-container">
      {showLoadingBanner && <Loadingbanner />}
      <h2>Login</h2>
    <div className="loginlogo-container"><img src={logo} className="loginimage" alt="Colin Casey OS Logo" /></div> 
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Login</button>
      
      </form>
      <p className='noaccounttext'>
          Don't have an account? <Link to="/register">Register here</Link>
        </p>
    </div>
  );
};

export default Login;
