import React, { useState, useEffect, useRef } from 'react';
import './css/SentMessageDisplay.css';
import HomeButton from './HomeButton';

const SentMessageDisplay = ({ initialMessage, messages, recipient, onBack, timestamp, onSendMessageAgain }) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [allMessages, setAllMessages] = useState(messages);
  const messageCountRef = useRef(allMessages.length);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [allMessages]);


  //   // useEffect(() => {
//   //   const intervalId = setInterval(async () => {
//   //     const response = await fetch('http://192.168.64.1:3500/messages');
//   //     const data = await response.json();
//   //     // Sort messages by timestamp
//   //     data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
//   //     setAllMessages(data);
//   //   }, 5000); // Poll every 5 seconds, adjust as needed.
//   //   // Cleanup interval on component unmount
//   //   return () => clearInterval(intervalId);
//   // }, []);


  ///office is 192.168.64.1 

  useEffect(() => {
    let isMounted = true; // Track if component is mounted to prevent state update on unmounted component
    const intervalId = setInterval(async () => {
      try {
        //10.1.10.15
        //const response = await fetch('http://192.168.64.1:3500/messages');
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/messages');
        //you should be able to change this to whispering basin as well 
        //const response = await fetch('http://10.1.10.15:3500/messages');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedMessages = data
          .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
          .filter((v, i, a) => a.findIndex(t => (t.timestamp === v.timestamp && t.from === v.from && t.to === v.to)) === i);
        
        // Determine if new messages have been fetched
        if (sortedMessages.length > messageCountRef.current && isMounted) {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        messageCountRef.current = sortedMessages.length;
        if (isMounted) {
          setAllMessages(sortedMessages);
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }, 5000);
  
    return () => {
      isMounted = false; // Set isMounted to false when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  const handleSend = () => {
    const newMsg = {
      body: newMessage,
      direction: 'outgoing',
      timestamp: new Date().toISOString()
    };
    setAllMessages(prev => [...prev, newMsg]);
    onSendMessageAgain(newMessage, recipient);
    setNewMessage('');
  };

  return (
    <div className="message-display-container">
      <div className="message-header">
        <button onClick={onBack} className="back-buttonMsg">&#x2190;</button>
        <center><span className="message-to">To: {recipient}</span></center>
      </div>

      <div className="message-contentSent">
        <div className="scrollable-messagesForSent">
          {allMessages.map((msg, index) => (
            <div 
              key={index}
              className={`message-body ${msg.direction === 'incoming' ? 'message-body-incoming message-bodyResponse' : 'message-body-outgoing'}`}
            >
              <p className='incomingmsg'>{msg.body}</p>
              <p className="message-time">{new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="message-input-container">
        <textarea
          className="new-message-input"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        ></textarea>
        <button className="send-new-message-button" onClick={handleSend}>Send</button>
      </div>
      <HomeButton />
    </div>
  );
};

export default SentMessageDisplay;
