import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomeScreen from './components/HomeScreen';
import Register from './components/Register';
import Login from './components/Login';
import SettingsScreen from './components/SettingsScreen';
import Messages from './components/Messages';
import Cclock from './components/clock';
import Calendar from './components/Calander';
import Phone from './components/Phone';
import Contacts from './components/contacts';
import Notes from './components/Notes';
import AddContact from './components/AddContact';
import ViewContact from './components/ViewContact';
import ComposeMessage from './components/ComposeMessage';
import Conversation from './components/Conversation';
import Camera from './components/Camera';
import './App.css';
import whitephone from './components/img/whitephone.png';
import messages from './components/img/messages.png';
import settings from './components/img/settingsc.png';
import music from './components/img/music.png';
import GMapsIframe from './components/GMapsIframe';
import Mail from './components/Mail';
import Email from './components/Email';
import Photos from './components/Photos';
import Calculator from './components/Calculator';
import Chase from './components/Chase';
import Music from './components/Music';

const App = () => {
  useEffect(() => {
   if (window.location.protocol !== 'https:') {
     window.location.href = 'https://' + window.location.href.split('//')[1];
   }
 }, []);
  
  const location = useLocation();
  const navigate = useNavigate();

  const userId = 1; //replace this later with the actual user id from auth logic 

  const dockApps = [
    { id: 1, icon: messages, background: '#46eb67', action: () => navigate('/messages') },
    { id: 2, icon: whitephone, background: '#46eb67', action: () => navigate('/phone') },
    { id: 3, icon: music, background: '#FC5A72', action: () => navigate('/music') },
    { id: 4, icon: settings, background: '#ababab', action: () => navigate('/settings')},
  ];

  const renderAppIcons = (appList) => (
    appList.map(app => (
      <div 
      key={app.id} 
      className={`app-icondock ${app.id === 4 ? '' : ''}`} 
      style={{ backgroundColor: app.background }} 
      onClick={app.action}
    >
        <img 
        src={app.icon} 
        alt={app.name} 
        style={{ 
          width: app.id === 4 ? '4em' : '42px', 
          height: app.id === 4 ? '4em' : '48px' 
        }} 
      />
      </div>
    ))
  );

  const showDock = location.pathname === '/home';

  const handleCancelCompose = () => {
    navigate('/messages');
  };

  return (
    <div className="App">
      <div className="phone-containerr">
        <div className="screen">
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Login />} />
            <Route path="/calander" element={<Calendar />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/chase" element={<Chase />} />
            <Route path="/clock" element={<Cclock />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mail" element={<Mail />} />
            <Route path="/email/:emailId" element={<Email />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/compose" element={<ComposeMessage onCancel={handleCancelCompose} />} />
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/gmaps" element={<GMapsIframe />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/add" element={<AddContact />} />
            <Route path="/phone" element={<Phone />} />
            <Route path="/photos" element={<Photos userId={userId} />} />
            <Route path="/camera" element={<Camera userId={userId} />} />
            <Route path="/contact/:contactId" element={<ViewContact />} />
            <Route path="/conversation/:recipient" element={<Conversation />} />
            <Route path="/music" element={<Music />} />
          </Routes>
          {showDock && <div className="dock">{renderAppIcons(dockApps)}</div>}
        </div>
      </div>
    </div>
  );
}

export default App;
