// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculator {
    width: 100%;
    max-width: 400px;
    margin: 2rem auto;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    overflow: hidden;
    background: #f9f9f9;
  }
  
  .calculator-display {
    background: #333;
    color: #fff;
    font-size: 2rem;
    padding: 1rem;
    text-align: right;
    height: 2em;
  }
  
  .calculator-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .calculator-buttons button {
    padding: 1.5rem;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    background: #fff;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .calculator-buttons button:hover {
    background: #f1f1f1;
  }
  
  .calculator-buttons button:active {
    background: #ddd;
  }
  
  .calculator-buttons button:nth-child(4n+1) {
    background: #ff9500;
    color: white;
  }
  
  .calculator-buttons button:nth-child(4n+1):hover {
    background: #ff8500;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Calculator.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,sCAAsC;IACtC,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,qCAAqC;EACvC;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".calculator {\n    width: 100%;\n    max-width: 400px;\n    margin: 2rem auto;\n    border-radius: 20px;\n    box-shadow: 0 5px 15px rgba(0,0,0,0.1);\n    overflow: hidden;\n    background: #f9f9f9;\n  }\n  \n  .calculator-display {\n    background: #333;\n    color: #fff;\n    font-size: 2rem;\n    padding: 1rem;\n    text-align: right;\n    height: 2em;\n  }\n  \n  .calculator-buttons {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n  }\n  \n  .calculator-buttons button {\n    padding: 1.5rem;\n    font-size: 1.5rem;\n    border: 1px solid #ccc;\n    background: #fff;\n    cursor: pointer;\n    transition: background 0.2s;\n  }\n  \n  .calculator-buttons button:hover {\n    background: #f1f1f1;\n  }\n  \n  .calculator-buttons button:active {\n    background: #ddd;\n  }\n  \n  .calculator-buttons button:nth-child(4n+1) {\n    background: #ff9500;\n    color: white;\n  }\n  \n  .calculator-buttons button:nth-child(4n+1):hover {\n    background: #ff8500;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
