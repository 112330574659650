import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './css/conversation.css';
import HomeButton from './HomeButton';
import toastr from 'toastr';

const Conversation = () => {
  const { recipient } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const currentUser = '9706101074'; // Replace this with the actual current user's identifier

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/messages/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredMessages = data.filter(msg => msg.recipient === recipient || msg.sender === recipient);
        filteredMessages.sort((a, b) => new Date(a.date) - new Date(b.date));
        setMessages(filteredMessages);
        
        if (initialLoad) {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          setInitialLoad(false); // Set initialLoad to false after the first scroll
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages(); // Initial fetch

    // Set interval to fetch messages every 4 seconds
    const intervalId = setInterval(fetchMessages, 4000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [recipient, initialLoad]);

  const sendMessage = useCallback(async (messageContent) => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      toastr.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/sendmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          to: recipient,
          body: messageContent,
        }),
      });

      const data = await response.json();
      if (data.success) {
        const newMsg = {
          id: messages.length + 1,
          content: messageContent,
          recipient,
          sender: currentUser,
          date: new Date().toISOString()
        };
        setMessages(prev => [...prev, newMsg]);
        setNewMessage('');
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        toastr.error(`Failed to send message: ${data.error}`);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toastr.error(`Error sending message: ${error.toString()}`);
    }
  }, [messages.length, recipient, currentUser]);

  const handleSend = () => {
    if (newMessage.trim()) {
      sendMessage(newMessage);
    } else {
      toastr.warning('Please enter a message.');
    }
  };

  return (
    <div className="conversation">
      <div className="conversation-header">
        <button onClick={() => window.location.href = '/messages'} className="back-buttonMsg">&#x2190;</button>
        <span className="message-to"><center>{recipient}</center></span>
      </div>
      <div className="message-contentSent">
        <div className="scrollable-messagesForSent">
          {messages.map(msg => (
            <div key={msg.id}
              className={`message-body ${msg.sender === recipient ? 'message-body-incomingConvo' : 'message-body-outgoing'}`}>
              <p>{msg.content}</p>
              <span className="message-time">{new Date(msg.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="message-input-container">
        <textarea
          className="new-message-input"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        ></textarea>
        <button className="send-new-message-button" onClick={handleSend}>Send</button>
      </div>
      <HomeButton />
    </div>
  );
};

export default Conversation;
