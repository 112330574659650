import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/SettingsScreen.css'; // Make sure your CSS file includes the necessary styles
import profile from './img/colincc.png';
import HomeButton from './HomeButton';
const initialOptions = [
  { name: 'Airplane Mode', icon: '✈️', hasSwitch: true, iconBg: '#FF9500' },
  { name: 'Wi-Fi', icon: '📶', hasSwitch: false, iconBg: '#007AFF' },
  { name: 'Bluetooth', icon: '🔵', hasSwitch: true, iconBg: '#007AFF' },
  { name: 'Cellular', icon: '📡', hasSwitch: false, iconBg: '#007AFF' },
  { name: 'Personal Hotspot', icon: '🔗', hasSwitch: false, iconBg: '#007AFF' },
  { name: 'VPN', icon: '🔒', hasSwitch: false, iconBg: '#007AFF' },
  // ... add other options with their respective background colors
  { name: 'Notifications', icon: '🔔', hasSwitch: false, iconBg: '#FF3B30' },
  { name: 'Sounds & Haptics', icon: '🔈', hasSwitch: false, iconBg: '#FF9500' },
  { name: 'Focus', icon: '🌙', hasSwitch: false, iconBg: '#5856D6' },
  { name: 'Screen Time', icon: '⏱', hasSwitch: false, iconBg: '#34C759' },
  { name: 'General', icon: '⚙️', hasSwitch: false, iconBg: '#8E8E93' },
  // ... continue with other options
];

const SettingsScreen = () => {
  const [options, setOptions] = useState(initialOptions);
  const navigate = useNavigate()

  const toggleSwitch = (index) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      isOn: !newOptions[index].isOn,
    };
    setOptions(newOptions);
  };

  const handleLogout = () => {
    sessionStorage.clear(); // This will clear the session storage
    navigate('/login');
    window.location.reload(); // Redirects the user to the root route which is your login route
  };



  return (

    <div className="settings">
        
      <div className="settings-header">
        <h1 style={{ paddingLeft: 10 + 'px' }}>Settings</h1>
        <input type="text" placeholder="Search" />
      </div>
      <div className="settings-profile">
        <img src={profile} alt="Profile" />
        <div>
          <div>Colin Casey</div>
          <div>Apple ID, iCloud+, Media & Purchases</div>
        </div>
      </div>
  <div className='divider'></div>
      <div className="settings-options">
        {options.map((option, index) => (
          <div key={index} className="settings-option">
            <span className="icon-settings" style={{ backgroundColor: option.iconBg, color: 'white' }}>{option.icon}</span>
            <span className="name">{option.name}</span>
            {option.hasSwitch && (
              <label className="switch">
                <input
                  type="checkbox"
                  checked={option.isOn || false}
                  onChange={() => toggleSwitch(index)}
                />
                <span className="slider round"></span>
              </label>
            )}
          </div>
        ))}
      </div><br/>
      <center>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
      </center>
      

      <HomeButton />
    </div>
  );
};

export default SettingsScreen;
