// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-button {
    /* position: fixed; */
    bottom: 140px;
    right: 20px;
    position: fixed; 
    margin-left: 2em;
    margin-top: .5em;
    width: 5em;
    z-index: 9999;
    color: white;
    height: 5em;
    border-radius: 50%;
    background-color: rgb(180, 173, 173);
    border: none;
    cursor: pointer;
    font-size: 1em;
    /* Add more styles as needed */
  }
  

  .homebuttonrow {
    max-width: 430px; 
    z-index: 9999;
    width: 100vw;
  }`, "",{"version":3,"sources":["webpack://./src/components/YourButtonStyles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,oCAAoC;IACpC,YAAY;IACZ,eAAe;IACf,cAAc;IACd,8BAA8B;EAChC;;;EAGA;IACE,gBAAgB;IAChB,aAAa;IACb,YAAY;EACd","sourcesContent":[".home-button {\n    /* position: fixed; */\n    bottom: 140px;\n    right: 20px;\n    position: fixed; \n    margin-left: 2em;\n    margin-top: .5em;\n    width: 5em;\n    z-index: 9999;\n    color: white;\n    height: 5em;\n    border-radius: 50%;\n    background-color: rgb(180, 173, 173);\n    border: none;\n    cursor: pointer;\n    font-size: 1em;\n    /* Add more styles as needed */\n  }\n  \n\n  .homebuttonrow {\n    max-width: 430px; \n    z-index: 9999;\n    width: 100vw;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
