// Register.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import toastr from 'toastr';
import logo from './img/ccOSlogo.png'; 
import 'toastr/build/toastr.min.css';
import Loadingbanner from './Loadingbanner';
import './css/Register.css';


const Register = () => {
  const [showLoadingBanner, setShowLoadingBanner] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    firstname: '', 
    lastname: '',  
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Local Endpoint 
     // const response = await fetch('http://localhost:3500/users/register', {

      //Production Endpoint
      const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        toastr.success('Registration successful!');
        navigate('/login'); // Navigate to login page after successful registration
      } else {
        throw new Error(data.error || 'Something went wrong during registration.');
      }
    } catch (error) {
      toastr.error(`Registration failed: ${error.message}`);
      console.error('Registration error:', error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingBanner(false); // Hide the loading banner after 1.5 seconds
    }, 1500);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div className="register-container">
    {showLoadingBanner && <Loadingbanner />}
      <h2>Registration</h2>
      <div className="loginlogo-container"><img src={logo} className="loginimage" alt="Colin Casey OS Logo" /></div> 
      <form className='registerform' onSubmit={handleSubmit}>
      <div>
          <label>Username</label>
          <input type="text" id="username"  name="username" value={formData.username} onChange={handleChange} required />
        </div>
        <div>
          <label>Email</label>
          <input type="email" id="email"  name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Password</label>
          <input type="password" id="password"  name="password" value={formData.password} onChange={handleChange} required />
        </div>
        <div>
          <label>First Name</label>
          <input type="text" id="firstname" name="firstname" value={formData.firstname} onChange={handleChange} required />
        </div>
        <div>
          <label>Last Name</label>
          <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} required />
        </div>
        <br/>
        <button type="submit">REGISTER</button>
      </form>
      <p className='logintext'>
          Have an account? <Link to="/login">Login</Link>
        </p>
    </div>
  );
};

export default Register;
