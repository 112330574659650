// import React, { useEffect, useState, useRef } from 'react';
// import './css/Mail.css';
// import { useNavigate } from 'react-router-dom';
// import HomeButton from './HomeButton';

// const Mail = () => {
//   const navigate = useNavigate();
//   const [emails, setEmails] = useState([]);
//   const wsRef = useRef(null);

//   wsRef.current = new WebSocket('ws://privatedocker-0e11c3e5520d.herokuapp.com:3500');

//   useEffect(() => {
//     const token = sessionStorage.getItem('token');
//     const fetchEmails = async () => {
//       try {
//         const response = await fetch('https://privatedocker-0e11c3e5520d.herokuapp.com:3500/emails/list', {
//           method: 'GET',
//           headers: {
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         });
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setEmails(data);
//       } catch (error) {
//         console.error('Error fetching emails:', error);
//       }
//     };
  

  
//     // Set up WebSocket connection
 
  
//     wsRef.current.onopen = () => {
//       if (wsRef.current.readyState === WebSocket.OPEN) {
//         wsRef.current.send(JSON.stringify({ action: 'startEmailCheck' }));
//       }
//     };
//     fetchEmails();
//     wsRef.current.onclose = () => {
//       if (wsRef.current.readyState === WebSocket.OPEN) {
//         wsRef.current.send(JSON.stringify({ action: 'stopEmailCheck' }));
//       }
//     };


  
//     // Cleanup WebSocket connection on unmount
//     return () => {
//       if (wsRef.current) {
//         if (wsRef.current.readyState === WebSocket.OPEN) {
//           //wsRef.current.send(JSON.stringify({ action: 'stopEmailCheck' }));
//           wsRef.current.close();
//         } else if (wsRef.current.readyState === WebSocket.CONNECTING) {
//           const handleOpenAndClose = () => {
//            // wsRef.current.send(JSON.stringify({ action: 'stopEmailCheck' }));
//             wsRef.current.close();
//           };
//           wsRef.current.addEventListener('open', handleOpenAndClose);
//         }
//       }
//     };
//   }, []);
  
  

//   const navigateToEmail = (emailId) => {
//     navigate(`/email/${emailId}`);
//   };

//   return (
//     <div className="mail">
//       <div className="mail-header">
//         <h1>Inbox</h1>
//       </div>
//       <div className="email-list">
//         {emails.map(email => (
//           <div key={email.id} className="email-item" onClick={() => navigateToEmail(email.id)}>
//             <div className="email-sender">{email.sender}</div>
//             <div className="email-subject">{email.subject}</div>
//             <div className="email-date">{new Date(email.date).toLocaleString()}</div>
//           </div>
//         ))}
//       </div>
//       <HomeButton />
//     </div>
//   );
// };

// export default Mail;


import React, { useEffect, useState } from 'react'; 
import './css/Mail.css';
import { useNavigate } from 'react-router-dom'; 
import HomeButton from './HomeButton';

const Mail = () => {
  const navigate = useNavigate(); 
  const [emails, setEmails] = useState([]);

  const navigateToEmail = (emailId) => {
    navigate(`/email/${emailId}`);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchEmails = async () => {
      try {
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/emails/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) { 
          throw new Error('Network response');
        } 
        const data = await response.json(); 
        setEmails(data);
      } catch (error) { 
        console.error('Error fetching emails:', error); 
      } 
    };

    fetchEmails();
  }, []);

  return (
    <div className="mail">
      <div className="mail-header"> 
        <h1>Inbox</h1> 
      </div> 
      <div className="email-list">
        {emails.map(email => (
          <div key={email.id} className="email-item" onClick={() => navigateToEmail(email.id)}>
            <div className="email-sender">{email.sender}</div>
            <div className="email-subject">{email.subject}</div> 
            <div className="email-date">{new Date(email.date).toLocaleString()}</div> 
          </div>
        ))}
      </div> 
      <HomeButton /> 
    </div>
  );
};

export default Mail;