import React, { useState, useCallback, useEffect } from 'react';
import './css/ComposeMessage.css';
import HomeButton from './HomeButton';
import SentMessageDisplay from './SentMessageDisplay';
import toastr from 'toastr';

const ComposeMessage = ({ onCancel }) => {
  const [allMessages, setAllMessages] = useState([]); 
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState('');
  const [sent, setSent] = useState(false);
  const [sentMessage, setSentMessage] = useState('');
  const [sentRecipient, setSentRecipient] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [isLogged, setIsLogged] = useState(true); // State to handle login status
  
  const token = sessionStorage.getItem('token');



  const onSend = useCallback(() => {
    // Ensure there is a token before sending
    if (token && message && recipient) {
      
     // fetch('https://whispering-basin-90626-d9b6eeca5ce9.herokuapp.com/http://44.210.187.185:3500/sendccos', {
      //fetch('http://192.168.1.86:3500/sendccos', {
       // fetch('http://192.168.64.1:3500/sendmessage', {
        fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/sendmessage', { //change this to whispering basin 
        //fetch('https://flexible-randomly-moccasin.ngrok-free.app/sendmessage', { //change this to whispering basin 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          to: recipient,
          body: message,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Message sent! Response:', data.data);
          setSentMessage(message);
          setSentRecipient(recipient);
          setTimestamp(new Date().toISOString());
          setSent(true);
          setMessage('');
          setRecipient('');
        } else {
          console.error('Failed to send message:', data.error);
          toastr.error(`Failed to send message: ${data.error}`);
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
        toastr.error(`Error sending message: ${error.toString()}`);
      });
    } else {
      toastr.warning('Please ensure all fields are filled correctly.');
    }
  }, [message, recipient, token]);
  


  useEffect(() => {
    if (!token) {
      toastr.error('You are not logged in.');
      setIsLogged(false); // Update the login status state
    }
  }, [token]);


  useEffect(() => {
    // When the message is ready to be sent, add it to the allMessages array
    if (readyToSend && message) {
      setAllMessages(prevMessages => [...prevMessages, { body: message, time: new Date().toISOString() }]);
      onSend();
      setReadyToSend(false);
    }
  }, [readyToSend, onSend, message]);

  const handleSendMessageAgain = useCallback((newMessage, newRecipient) => {
    // Add the new message to the array of all messages
   // setAllMessages(prevMessages => [...prevMessages, { body: newMessage, time: new Date().toISOString() }]);
    setMessage(newMessage);
    setRecipient(newRecipient);
    setReadyToSend(true);
  //};
}, []);

  // Don't render the component if not logged in
  if (!isLogged) {
    return null;
  }

  if (sent) {
    return (
      <SentMessageDisplay
        initialMessage={sentMessage}
        messages={allMessages}
        recipient={sentRecipient}
        timestamp={timestamp}
        onBack={() => setSent(false)}
        onSendMessageAgain={handleSendMessageAgain}
      />
    );
  }

  return (
    <div className="compose-container">
      <div className="compose-header">
        <button className="cancel-button" onClick={onCancel}>Cancel</button>
        <span className="new-message-title">New Message</span>
        <button className="send-button" onClick={() => setReadyToSend(true)} disabled={!message || !recipient}>Send</button>
      </div>
      <div className="compose-to">
        <input
          type="text"
          placeholder="To:"
          className="to-input"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        />
      </div>
      <div className="compose-message">
        <textarea
          className="message-input"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <HomeButton />
    </div>
  );
};

export default ComposeMessage;
