// LoadingBanner.js
import React from 'react';
import logo from './img/ccOSlogo.png'; 
import './css/Loadingbanner.css';

const Loadingbanner = () => {
  return (
    <div className="loading-banner">
      <img src={logo} className="loadingimage" alt="Colin Casey OS Logo" />
    </div>
  );
};

export default Loadingbanner;
