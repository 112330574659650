import React, { useEffect, useState } from 'react';
import './css/Messages.css';
import compose from './img/compose.png';
import { useNavigate } from 'react-router-dom';
import HomeButton from './HomeButton';

const Messages = () => {
  const navigate = useNavigate();
  const [groupedMessages, setGroupedMessages] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState(''); // State to store user's phone number

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchUserPhoneNumber = async () => {
      // Fetch user's phone number from an appropriate endpoint or storage
      const userPhone = '9706101074'; // Replace with actual fetching logic
      setUserPhoneNumber(userPhone);
    };

    const fetchMessages = async () => {
      try {
        const response = await fetch('https://pdocker2-ba81c9aadcfa.herokuapp.com/messages/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Add a direction field to each message
        const messagesWithDirection = data.map(message => ({
          ...message,
          direction: message.sender === userPhoneNumber ? 'outgoing' : 'incoming'
        }));

        // Group messages by recipient for outgoing and sender for incoming
        const messagesByContact = messagesWithDirection.reduce((acc, message) => {
          const contact = message.direction === 'outgoing' ? message.recipient : message.sender;
          if (!acc[contact] || new Date(acc[contact].date) < new Date(message.date)) {
            acc[contact] = message;
          }
          return acc;
        }, {});

        // Convert the object to an array and sort by date
        const grouped = Object.values(messagesByContact).sort((a, b) => new Date(b.date) - new Date(a.date));

        setGroupedMessages(grouped);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    if (token) {
      fetchUserPhoneNumber(); // Fetch user's phone number
      fetchMessages(); // Initial fetch

      // Set interval to fetch messages every 2.5 seconds
      const intervalId = setInterval(fetchMessages, 2500);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [userPhoneNumber]); // Re-run effect if userPhoneNumber changes

  const navigateToCompose = () => {
    navigate('/compose');
  };

  const navigateToConversation = (contact) => {
    navigate(`/conversation/${contact}`);
  };

  return (
    <div className="messages">
      <div className="messages-header">
        <h1>Messages</h1>
        <button className="buttontop" onClick={navigateToCompose}>
          <img src={compose} width="20px" alt="Compose"/>
        </button>
        <input type="text" placeholder="Search" />
      </div>
      <div className="message-list">
        {groupedMessages.map(msg => (
          <div key={msg.id} className="message-item" onClick={() => navigateToConversation(msg.direction === 'outgoing' ? msg.recipient : msg.sender)}>
            <div className="message-content">
              <div className="message-name">{msg.direction === 'outgoing' ? msg.recipient : msg.sender}</div>
              <div className="message-preview">{msg.content}</div>
            </div>
            <div className="message-time">
              {new Date(msg.date).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
            </div>
          </div>
        ))}
      </div>
      <HomeButton />
    </div>
  );
};

export default Messages;
